/* src/PhoneKeyboard.css */
.phone-keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 5px 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  width: 90%;
  max-width: 300px;
  margin: auto;
}

.display {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
  font-size: 18px;
  border-radius: 5px;
}

.keypad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  width: 100%;
}

.keypad button {
  padding: 10px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  background-color: #dcdcdc;
  cursor: pointer;
}

.keypad button:hover {
  background-color: #bbb;
}

.keypad .backspace {
  background-color: #f77;
}

.keypad .clear {
  background-color: #77f;
  color: #fff;
}

.keypad .call {
  background-color: #52c41a;
  color: #fff;
}

.keypad .backspace:hover {
  background-color: #f55;
}

.keypad .clear:hover {
  background-color: #55f;
}

.custom-table-row {
  background-color: #f5ecda !important;
  opacity: .7 !important;
}

/* Responsive styles */
@media (max-width: 600px) {
  .phone-keyboard {
      padding: 5px;
  }

  .display {
      padding: 10px;
      font-size: 16px;
  }

  .keypad button {
      padding: 8px;
      font-size: 18px;
  }

  .keypad .backspace,
  .keypad .clear,
  .keypad .call {
      font-size: 16px;
  }
}
