.flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.flex > p {
  margin-bottom: 0;
}

.w-250{
  width: 250px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.grid {
  display: grid;
}

.grid-rows-3 {
  grid-template-rows: 1fr 1fr 1fr;
}

.flex-column {
  flex-direction: column;
}

.justify-content-around {
  justify-content: space-around;
}

.text-20 {
  font-size: 20px;
}

.bg-blue {
  background-color: #0592f5;
}

.text-center {
  text-align: center;
}

.text-white {
  color: white;
}

.h-50px {
  height: 50px;
}

.line-height-50 {
  line-height: 50px;
}

.grid-cols-2 {
  grid-template-columns: 1fr 1fr;
}

.bg-lightPurple {
  background-color: rgb(173, 162, 242);
}

.bg-boldPurple {
  background-color: rgb(157, 143, 242);
}